import getConfig from 'next/config';
import { AuraPhase } from '@src/models';

export const fetchAuraPhaseUsedOnInterview = async (
  applicationId: string
): Promise<AuraPhase | null> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/aura/interview/${applicationId}`
  );
  const data = (await result.json()) as AuraPhase;
  if (result.status === 200) return data;
  return null;
};

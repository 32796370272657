import getConfig from 'next/config';
import { AuraQuestion, AuraQuestionResponse, AuraSections } from '@src/models';

const fetchDataWithTimeout = (uri: string, callTimeout: number) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), callTimeout);
  const fetchPromise = fetch(uri, { signal: controller.signal }).then(response => {
    clearTimeout(timeoutId);
    return response;
  });

  return fetchPromise;
};

export const fetchAuraQuestions = async (applicationId: string, sectionId: AuraSections): Promise<AuraQuestion[]> => {
  const { publicRuntimeConfig } = getConfig();
  const timeout = 5000; // Timeout duration in milliseconds
  const url = `${publicRuntimeConfig.host}/api/aura/questions/${applicationId}/${sectionId}`;

  const result = await fetchDataWithTimeout(url, timeout).then(async (response: Response) => {
    // Handle the successful response
    return response;
  });

  // const result = await fetch(`${publicRuntimeConfig.host}/api/aura/questions/${applicationId}/${sectionId}`);
  const { questions, etag } = (await result.json()) as AuraQuestionResponse;
  // set etag in localstorage
  localStorage.setItem('AURA_ETAG', etag);
  // return array of questions
  return questions;
};

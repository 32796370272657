import getConfig from 'next/config';
import { AstuteCheckResponse, Bank, SelectListGroup } from '@src/models';
import { BankBranch } from '@src/models/BankBranch';

export const fetchAstuteCheck = async (
  identifier: string, 
  referenceNumber: string
): Promise<AstuteCheckResponse> => {
  const { publicRuntimeConfig } = getConfig();

  const result = await fetch(
    `${publicRuntimeConfig.host}/api/astute/${identifier}/${referenceNumber}`
  );  
  const json = (await result.json()) as AstuteCheckResponse;

  return json;
};

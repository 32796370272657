import getConfig from 'next/config';
import { AuraQuestion, AuraQuestionByIdResponse } from '@src/models';

export const fetchAuraSearchResultsForQuestion = async (
  quoteNumber: string,
  questionID: number,
  keyword: string
): Promise<AuraQuestion> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/aura/search/${quoteNumber}/${questionID}/${keyword}`
  );
  const { question, etag } = (await result.json()) as AuraQuestionByIdResponse;
  // set etag in localstorage
  localStorage.setItem('AURA_ETAG', etag);
  // return array of questions
  return question;
};

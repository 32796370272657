import React, { useState, createContext } from 'react';

type Props = {};

type InitialValues = {
  isSnlOpen: boolean;
  setIsSnlOpen: (isOpen: boolean) => void;
};

const initialValues: InitialValues = {
  isSnlOpen: false,
  setIsSnlOpen: isOpen => isOpen,
};

export const SNLContext = createContext(initialValues);

export const SNLProvider: React.FC<Props> = ({ children }) => {
  const [isSnlOpen, setIsSnlOpen] = useState(false);

  return (
    <SNLContext.Provider
      value={{
        isSnlOpen,
        setIsSnlOpen,
      }}>
      {children}
    </SNLContext.Provider>
  );
};

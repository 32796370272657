import { useContext } from 'react';
import getConfig from 'next/config';
import { FormData } from '@src/models';
import { FormDataContext, SNLContext } from '@src/contexts';
import { useApplication } from './useApplication';

function useFormData() {
  const { setFormData } = useContext(FormDataContext);
  const { setIsSnlOpen } = useContext(SNLContext);
  const { update } = useApplication();

  const updateFormData = async (formValues: FormData): Promise<void> => {
    await update({
      formData: formValues,
    });

    setFormData(formValues);
    setIsSnlOpen(false);
  };

  return {
    updateFormData,
  };
}

export { useFormData };

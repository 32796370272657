import { ProgressSection, FormData, Quote, AstuteCheckResponse } from '@src/models';
import getConfig from 'next/config';

export const updateAuraApplication = async (
  applicationId: string,
  quote: Quote,
  formData: FormData,
): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      applicationId,
      quote,
      formData,
      progress: [], // fake progress
      shouldSendRAREmail: false, //fake shouldSendRAREmail
      isFmiReplacement: false, // fake isFmiReplacement
      isOtherReplacement: false, // fake isOtherReplacement
    }),
  };
  const result = await fetch(`${publicRuntimeConfig.host}/api/auraUpdate`, requestOptions);
  const json = (await result.json());
  return json;
};

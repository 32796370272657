import React, { useState, createContext } from 'react';
import { User } from '../models/User';

type Props = {
  value: User;
};

type InitialValues = {
  user: User;
  setUser: (user: User) => void;
};

export const initialUserContextValues: InitialValues = {
  user: {
    email: '',
    id: '',
    isAdviserOnQuote: false,
    isLifeInsured: false,
    reviewModalOpen: false,
  },
  setUser: user => user,
};

export const UserContext = createContext(initialUserContextValues);

export const UserProvider: React.FC<Props> = ({ children, value }) => {
  const [user, setUser] = useState(value);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}>
      {children}
    </UserContext.Provider>
  );
};

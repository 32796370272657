import getConfig from 'next/config';

import { Application } from '@src/models';
import {
  initialProgressContextValues,
  initialFormDataContextValues,
  initialQuoteContextValues,
  ApplicationContext,
} from '@src/contexts';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { getApplication } from '../utils/getApplication';
import { saveApplication } from '../utils/saveApplication';
import { saveApplicationPartial } from '../utils/saveApplicationPartial';
import { removeSubSection } from '../../src/utils/filterProgress';

export const initialApplicationState: Application = {
  quote: initialQuoteContextValues.quote,
  progress: initialProgressContextValues.progress,
  formData: initialFormDataContextValues.formData,
  shouldSendRAREmail: false,
  isFmiReplacement: false,
  isOtherReplacement: false,
  applicationId: '',
};
interface ApplicationUpdate {
  update: (app: Partial<Application>) => Promise<boolean>;
  setApplicationReplacement: (
    isFmiReplacement: boolean,
    isOtherReplacement: boolean
  ) => void;
}
async function checkApplication(
  id: string
): Promise<{
  exists: boolean;
  application: Application | null;
}> {
  const { publicRuntimeConfig } = getConfig();
  if (publicRuntimeConfig.useMocks) {
    console.info('Application using mocks');
    await Promise.resolve({});
    return { exists: false, application: initialApplicationState };
  }

  const response = await getApplication(id);
  if (response.status === 200) {
    return { exists: true, application: response.application };
  }
  if (response.status === 404) {
    return { exists: false, application: null };
  }
  throw Error(`Failed to fetch application - ${response.status}`);
}

async function setInitialState(id: string): Promise<Application> {
  console.info('Application get quote');

  const app = await saveApplication({ applicationId: id });
  console.log('Complete saving initial application');

  return app;
}

export async function loadApplication(id: string): Promise<Application> {
  const app = await checkApplication(id);
  const appl = app.exists ? app.application : await setInitialState(id);
  return appl;
}

export function useApplication(): ApplicationUpdate {
  const { application, setApplication } = useContext(ApplicationContext);
  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const routeId = router.query.id as string;

  async function update(ap: Partial<Application>): Promise<boolean> {
    if (!publicRuntimeConfig.useMocks)
      await saveApplicationPartial(
        routeId,
        ap.progress,
        ap.formData,
        ap.shouldSendRAREmail,
        ap.isFmiReplacement,
        ap.isOtherReplacement,
      );

    return true;
  }

  const setApplicationReplacement = (
    isFmiReplacement: boolean,
    isOtherReplacement: boolean
  ) =>
    setApplication({
      ...application,
      isFmiReplacement: isFmiReplacement,
      isOtherReplacement: isOtherReplacement,
    });

  return { update, setApplicationReplacement };
}

import { Application } from '@src/models';
import getConfig from 'next/config';

export const saveApplication = async (app: {
  applicationId: string;
}): Promise<Application> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(app),
  };

  const response = await fetch(
    `${publicRuntimeConfig.host}/api/application`,
    requestOptions
  );

  return (await response.json()) as Application;
};

import getConfig from 'next/config';
import { Application } from '@src/models';

type ReturnedApp = {
  application: Application | null;
  status: number;
};

export const getApplication = async (
  applicationId: string
): Promise<ReturnedApp> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/application/${applicationId}`
  );

  // application exists
  if (result.status === 200) {
    return {
      application: (await result.json()) as Application,
      status: result.status,
    };
  }

  // application does not exist
  if (result.status === 404) {
    return {
      application: null,
      status: result.status,
    };
  }

  // could not reach application api
  return {
    application: null,
    status: result.status,
  };
};

import { useContext } from 'react';
import { FormDataContext } from '@src/contexts';

type SignatureRequired = {
  fullName: string;
  emailAddress: string;
};

function useSignaturesRequired() {
  const { formData } = useContext(FormDataContext);

  const getLifeInsured = (): SignatureRequired => {
    return {
      fullName: `${formData.lifeInsuredsDetails.firstName} ${formData.lifeInsuredsDetails.surname}`,
      emailAddress: formData.lifeInsuredsDetails.emailAddress,
    };
  };

  const getPolicyHolder = (): SignatureRequired => {
    if (formData.policyholderDetails.isPolicyholder === 'yes') {
      return {
        fullName: '',
        emailAddress: '',
      };
    }

    const policyHolder = formData.policyholderDetails.policyholders[0];

    if (policyHolder.policyholderType === 'person')
      return {
        fullName: `${policyHolder.policyholderFirstName} ${policyHolder.policyholderLastName}`,
        emailAddress: policyHolder.policyholderEmailAddress,
      };

    if (policyHolder.policyholderType === 'business')
      return {
        fullName: policyHolder.businessAuthorisedSignatoryFullName,
        emailAddress: policyHolder.businessAuthorisedSignatoryEmailAddress,
      };

    if (policyHolder.policyholderType === 'trust')
      return {
        fullName: policyHolder.trustAuthorisedSignatoryFullName,
        emailAddress: policyHolder.trustAuthorisedSignatoryEmailAddress,
      };

    return {
      fullName: '',
      emailAddress: '',
    };
  };

  const getAccountHolder = (): SignatureRequired => {
    const { accountHolder, fullname, email } = formData.debitOrder;

    if (accountHolder === 'Third Party') {
      return {
        fullName: fullname,
        emailAddress: email,
      };
    }

    // check for the account holders name in life insured
    if (getLifeInsured().fullName === accountHolder) {
      return {
        fullName: getLifeInsured().fullName,
        emailAddress: getLifeInsured().emailAddress,
      };
    }

    // check for the account holders name in policyholder
    if (getPolicyHolder().fullName === accountHolder) {
      return {
        fullName: getPolicyHolder().fullName,
        emailAddress: getPolicyHolder().emailAddress,
      };
    }
    // else the accountholder
    return {
      fullName: getLifeInsured().fullName,
      emailAddress: getLifeInsured().emailAddress,
    };
  };

  const lifeInsured = getLifeInsured();
  const policyHolder = getPolicyHolder();
  const accountHolder = getAccountHolder();

  return {
    lifeInsured,
    policyHolder,
    accountHolder,
  };
}

export { useSignaturesRequired };

import getConfig from 'next/config';
import { AdviserIdentity } from '@src/models';

export async function fetchAdviserIdentity(
  maverickId: string
): Promise<AdviserIdentity> {
  const { publicRuntimeConfig } = getConfig();
  console.log(`${publicRuntimeConfig.host}/api/adviseridentity?maverickId=${maverickId}`);
  const adviser = await fetch(
    `${publicRuntimeConfig.host}/api/adviseridentity?maverickId=${maverickId}`
  ).then(r => r.json());
  return adviser as AdviserIdentity;
}

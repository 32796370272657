function getBenefitTerm(paymentTerm: string): string {
  switch (paymentTerm) {
    case '6':
      return `for Fixed Term 6 months`;
    case '12':
      return `for Fixed Term 12 months`;
    case '24':
      return `for Fixed Term 2 years`;
    case '60':
      return `for Fixed Term 5 years`;
    case '120':
      return `for Fixed Term 10 years`;
    case '180':
      return `for Fixed Term 15 years`;
    case '240':
      return `for Fixed Term 20 years`;
    case '18Y':
      return `to child's 18th birthday`;
    case '21Y':
      return `to child's 21st birthday`;
    case '24Y':
      return `to child's 24th birthday`;
    case 'R55':
      return `to Insured's Retirement Age 55`;
    case 'R60':
      return `to Insured's Retirement Age 60`;
    case 'R65':
      return `to Insured's Retirement Age 65`;
    case 'R70':
      return `to Insured's Retirement Age 70`;
    case 'D':
      return `to Insured's whole of Life`;
    default:
      return '';
  }
}

export { getBenefitTerm };

import getConfig from 'next/config';
import {
  AuraAnswer,
  AuraQuestion,
  AuraQuestionResponse,
  AuraSections,
} from '@src/models';

export const fetchAuraAnswer = async (
  body: AuraAnswer,
  questionID: number,
  applicationId: string,
  sectionId: AuraSections
): Promise<AuraQuestion[]> => {
  const { publicRuntimeConfig } = getConfig();
  const etagAura = localStorage.getItem('AURA_ETAG');
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/aura/answer/${questionID}/${applicationId}/${sectionId}`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'etag-aura': etagAura,
      },
    }
  );
  const { questions, etag } = (await result.json()) as AuraQuestionResponse;
  // set etag in localstorage if it exists
  if (etag) {
    localStorage.setItem('AURA_ETAG', etag);
  }
  // return array of questions
  return questions;
};

import React, { useState, createContext } from 'react';
import { ProgressSection } from '../models';
import { progressInitialValues } from './initialValues';

type Props = {
  value: ProgressSection[];
};

type InitialValues = {
  progress: ProgressSection[];
  setProgress: (progress: ProgressSection[]) => void;
  saving: boolean;
  setSaving: (isSaving: boolean) => void;
};

export const initialProgressContextValues: InitialValues = {
  progress: progressInitialValues,
  setProgress: progress => progress,
  saving: false,
  setSaving: isSaving => isSaving,
};

export const ProgressContext = createContext(initialProgressContextValues);

export const ProgressProvider: React.FC<Props> = ({ children, value }) => {
  const [progress, setProgress] = useState(value);
  const [saving, setSaving] = useState(false);
  return (
    <ProgressContext.Provider
      value={{
        progress,
        setProgress,
        saving,
        setSaving,
      }}>
      {children}
    </ProgressContext.Provider>
  );
};

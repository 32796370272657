import getConfig from 'next/config';
import { Industry, SelectList } from '@src/models';

export const fetchIndustries = async (): Promise<SelectList[]> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(`${publicRuntimeConfig.host}/api/industries/`);
  const json = (await result.json()) as Industry[];

  const mapping = json.map(item => ({
    label: item.description,
    value: item.id.toString(),
  }));
  return mapping;
};

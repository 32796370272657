import { useState } from 'react';

type Props<T> = {
  onClear: () => Promise<void>;
  setValue: (newValue: T) => void;
  showAsk: (newValue: T) => boolean;
  value: T;
};

export interface ShouldClearAll {
  onAcceptLoseInfo: () => void;
  handleChange: (e) => boolean;
  onCancelLoseInfo: () => void;
  isOpen: boolean;
}
export function useShouldClearList<T>({
  onClear,
  showAsk,
  setValue,
  value,
}: Props<T>): ShouldClearAll {
  const [isOpen, setIsOpen] = useState(false);
  const [val, setVal] = useState<T>(value);
  function onAcceptLoseInfo() {
    onClear();
    setIsOpen(false);
    setValue(val);
  }
  function handleChange(e) {
    const curVal = e.target.value;
    if (showAsk(curVal)) {
      setIsOpen(true);
      setVal(curVal);
      return false;
    }
    setValue(curVal);
    return true;
  }
  function onCancelLoseInfo() {
    setIsOpen(false);
  }
  return { isOpen, onCancelLoseInfo, onAcceptLoseInfo, handleChange };
}

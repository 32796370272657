import { useState, useEffect } from 'react';
import getConfig from 'next/config';

function useFetch<T>(url): [T, boolean] {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { publicRuntimeConfig } = getConfig();
  async function fetchUrl() {
    const response = await fetch(`${publicRuntimeConfig.host}${url}`);
    const json = await response.json();
    setData(json);
    setLoading(false);
  }
  useEffect(() => {
    fetchUrl();
  }, []);
  return [(data as unknown) as T, loading];
}
export { useFetch };

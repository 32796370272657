import getConfig from 'next/config';
import { ValidatedQuote } from '@src/models';

export async function fetchValidateQuote(url: string): Promise<ValidatedQuote> {
  const { publicRuntimeConfig } = getConfig();
  const data = await fetch(`${publicRuntimeConfig.host}${url}`).then(r =>
    r.json()
  );
  return data as ValidatedQuote;
}

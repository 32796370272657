import { useContext, useState, useEffect } from 'react';
import { useSection } from '@src/hooks';
import { ProgressContext, UserContext } from '../contexts';
import { useApplication } from './useApplication';

function useProgress() {
  const { progress, saving, setSaving } = useContext(ProgressContext);
  const [userProgress, setUserProgress] = useState([]);
  const { user } = useContext(UserContext);
  const {
    activeSection,
    activeSubSection,
    nextSection,
    nextSubSection,
  } = useSection();
  const { update } = useApplication();

  const updateProgress = async () => {
    // NO MORE SECTIONS: APPLICATION IS DONE
    // 1. mark current section as complete
    if (!nextSection) {
      if (nextSubSection) {
        activeSubSection.isComplete = true;
      } else {
        // complete application if not life insured
        if (!user.isLifeInsured) {
          console.log('completing application...');
          activeSection.isComplete = true;
          if (activeSubSection) activeSubSection.isComplete = true;
        }
      }
    } else {
      // IF THE NEXT SECTION DOES NOT CONTAIN A SUBSECTION
      // AND THERE IS NO NEXT SUBSECTION
      if (nextSection.subsections.length === 0 && !nextSubSection) {
        if (activeSubSection) {
          activeSubSection.isComplete = true;
        } else {
          activeSection.isComplete = true;
        }
      }
      // IF THE NEXT SECTION CONTAINS A SUBSECTION
      // AND THERE IS NO NEXT SUBSECTION
      if (nextSection.subsections.length > 0 && !nextSubSection) {
        // if all subsections are complete then mark the section complete
        if (
          activeSection.subsections.filter(x => x.isComplete).length ===
          activeSection.subsections.length
        ) {
          activeSection.isComplete = true;
        }
        if (activeSubSection) {
          activeSubSection.isComplete = true;
        }
      }
      // IF THE NEXT SECTION IS A SUBSECTION
      if (nextSubSection) {
        activeSubSection.isComplete = true;
      }

      // IF ALL SUBSECTIONS ARE COMPLETE THEN COMPLETE THE SECTION
      const hasIncompleteSections = activeSection.subsections.find(
        x => !x.isComplete
      );
      if (!hasIncompleteSections) {
        activeSection.isComplete = true;
      }
    }

    setSaving(true);
    // save the updated context
    await update({
      progress,
    })
      .then(() => setSaving(false))
      .catch(() => setSaving(false));
  };

  useEffect(() => {
    if (user.isLifeInsured) {
      setUserProgress(
        progress.filter(
          x =>
            x.name !== 'IFA Agreements' &&
            x.name !== 'Independent Financial Adviser Agreement'
        )
      );
    } else {
      setUserProgress(progress);
    }
  }, [progress]);

  const isAppComplete =
    progress.filter(
      section =>
        !section.isComplete &&
        section.name !== 'IFA Agreements' &&
        section.name !== 'Independent Financial Adviser Agreement' &&
        section.subsections.filter(subSection => !subSection.isComplete)
          .length > 0
    ).length === 0;

  const setApplicationSaving = (isSaving: boolean) => setSaving(isSaving);

  return {
    updateProgress,
    userProgress,
    isAppComplete,
    saving,
    setApplicationSaving,
  };
}

export { useProgress };

import getConfig from 'next/config';
import { AuraDoctor, AddDoctorResponse } from '@src/models';

export const fetchAuraUpdateDoctor = async (
  body: AuraDoctor,
  applicationId: string,
  key: string
): Promise<AuraDoctor> => {
  const { publicRuntimeConfig } = getConfig();
  const etagAura = localStorage.getItem('AURA_ETAG');
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/aura/doctor/addDoctor/${applicationId}/${key}`,
    {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'etag-aura': etagAura,
      },
    }
  );
  const { doctor, etag } = (await result.json()) as AddDoctorResponse;
  localStorage.setItem('AURA_ETAG', etag);
  return doctor;
};

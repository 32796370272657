import { useContext } from 'react';
import { useRouter } from 'next/router';
import { toSlug } from '@src/utils';
import { ProgressContext, UserContext } from '../contexts';
import { progressInitialValues } from '@src/contexts/initialValues';

function useSection() {
  const { progress } = useContext(ProgressContext);
  const router = useRouter();
  const { user } = useContext(UserContext);

  const routerSlug = router.pathname.split('/')[3];

  const aciveSectionFromSlug = progress.find(
    x =>
      toSlug(x.name) === routerSlug ||
      x.subsections.find(s => toSlug(s.name) === routerSlug)
  );

  const activeSectionFromInitial = progressInitialValues.find(
    x =>
      toSlug(x.name) === routerSlug ||
      x.subsections.find(s => toSlug(s.name) === routerSlug)
  );

  if (activeSectionFromInitial) {
    activeSectionFromInitial.isComplete = true;
    if (activeSectionFromInitial.subsections.length > 0) {
      activeSectionFromInitial.subsections.forEach(subsection => {
        subsection.isComplete = true;
      })
    }
  }

  const activeSection = aciveSectionFromSlug || activeSectionFromInitial;

  const activeSubSection =
    activeSection && activeSection.subsections
      ? activeSection.subsections.find(s => toSlug(s.name) === routerSlug)
      : null;

  const getNextSection = () => {
    const currentIndex = progress.indexOf(activeSection);
    return progress[currentIndex + 1];
  };
  const nextSection = activeSection ? getNextSection() : null;

  const getNextSubSection = () => {
    if (activeSubSection?.name === 'FIC Declaration' && user.isLifeInsured)
      return null;
    const currentIndex = activeSection.subsections.indexOf(activeSubSection);
    return activeSection.subsections[currentIndex + 1];
  };
  const nextSubSection = activeSection ? getNextSubSection() : null;

  return {
    activeSection,
    activeSubSection,
    nextSection,
    nextSubSection,
  };
}

export { useSection };

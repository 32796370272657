import React, { useState, createContext } from 'react';
import { Quote } from '../models/Quotes';

type Props = {
  value: Quote;
};

type InitialValues = {
  quote: Quote;
  setQuote: (quote: Quote) => void;
};

export const initialQuoteContextValues: InitialValues = {
  quote: {
    ifa: {
      accreditedForAnnualComm: '',
      bdName: '',
      brokerage: '',
      cellPhone: '',
      externalConsultantName: '',
      facName: '',
      faisNumber: 0,
      fmiNumber: '',
      mavericPK: 0,
      name: '',
      packages: '',
      phone: '',
      priNumber: '',
    },
    policyHolder: {
      salariedCostToCompany: 0,
      salariedNetSalary: 0,
      spouseMonthlySalary: '',
    },
    quoteDetail: {
      answerSetGuid: '',
      questionSetGuid: '',
      quoteNumber: '',
      quoteVersion: '',
      description: '',
      standardMedicalRequirements: [],
      brokerCommissionAnnual: 0,
      brokerCommissionAnnual2: 0,
      brokerCommissionAsAndWhen: 0,
      brokerCommissionAsAndWhenPercentageSelected: 0,
      brokerCommissionPercentageSelected: 0,
      brokerCommissionTypeSelected: 0,
      brokerCommissionUpfrontPercentageSelected: true,
      dateOfCommencement: new Date(),
      eventClass: '',
      healthClass: '',
      packageID: 0,
      premium: 0,
      rateSetVersion: 0,
      insuranceNeed: '',
      createdOnDate: new Date(),
      createdByFirstName: '',
      createdBySurname: '',
      answeredOnDate: new Date(),
      answeredByFirstName: '',
      answeredBySurname: '',
    },
    lifeInsured: {
      educationLevel: 0,
      educationText: '',
      occRating: '',
      occupation: '',
      recommendedOccupation: '',
      workOutsideRSA: true,
      title: '',
      firstName: '',
      surname: '',
      dateOfBirth: new Date(),
      gender: 'Male',
      isSmoker: false,
      ageNext: 0,
      employmentType: '',
      annualSalary: 0,
      grossMonthlySalary: 0,
      netMonthlySalary: 0,
      spouseMonthlySalary: 0,
      socialRating: '',
      travelPercentage: 0,
      manualDuty: {
        climb: false,
        handHeld: false,
        heavy: false,
        hold: false,
        interaction: false,
        lifting: false,
        manualWork: false,
        manualPercentage: 0,
      },
      showManualDutiesQuestionsOnApp: true,
    },
    benefits: [],
  },
  setQuote: quote => quote,
};

export const QuoteContext = createContext(initialQuoteContextValues);

export const QuoteProvider: React.FC<Props> = ({ children, value }) => {
  const [quote, setQuote] = useState(value);

  return (
    <QuoteContext.Provider
      value={{
        quote,
        setQuote,
      }}>
      {children}
    </QuoteContext.Provider>
  );
};

import React, { useState, createContext } from 'react';
import { FormData } from '../models';
import { formDataInitialValues } from './initialValues';

type Props = {
  value: FormData;
};

type InitialValues = {
  formData: FormData;
  setFormData: (formData: FormData) => void;
};

export const initialFormDataContextValues: InitialValues = {
  formData: formDataInitialValues,
  setFormData: (data: FormData) => data,
};

export const FormDataContext = createContext(initialFormDataContextValues);

export const FormDataProvider: React.FC<Props> = ({ children, value }) => {
  const [formData, setFormData] = useState(value);

  return (
    <FormDataContext.Provider
      value={{
        formData,
        setFormData,
      }}>
      {children}
    </FormDataContext.Provider>
  );
};

import { AuraDoctor } from '@src/models';
import React, { useState, createContext } from 'react';
import { Aura } from '../models/Aura';

type Props = {
  value: Aura;
};

type InitialValues = {
  aura: Aura;
  setAura: (aura: Aura) => void;
  doctors: AuraDoctor[];
  setDoctors: (doctors: AuraDoctor[]) => void;
};

export const initialAuraContextValues: InitialValues = {
  aura: {
    currentPhase: '1',
    phaseUsedWithApplication: '1',
  },
  setAura: aura => aura,
  doctors: [],
  setDoctors: doctors => doctors,
};

export const AuraContext = createContext(initialAuraContextValues);

export const AuraProvider: React.FC<Props> = ({ children, value }) => {
  const [aura, setAura] = useState(value);
  const [doctors, setDoctors] = useState([]);

  return (
    <AuraContext.Provider
      value={{
        aura,
        setAura,
        doctors,
        setDoctors,
      }}>
      {children}
    </AuraContext.Provider>
  );
};

import getConfig from 'next/config';



export const fetchInputXml = async (applicationId: string): Promise<string> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(
    `${publicRuntimeConfig.host}/api/aura/inputXml/${applicationId}`
  );
  const data = (await result.json()) as { requestXml: string };
  return data.requestXml;
};

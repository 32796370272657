import React, { useState, createContext } from 'react';

type Props = {
  value: any;
};

type InitialValues = {
  application: {
    isFmiReplacement: boolean;
    isOtherReplacement: boolean;
  };
  setApplication: (values) => void;
};

export const initialApplicationContextValues: InitialValues = {
  application: {
    isFmiReplacement: false,
    isOtherReplacement: false,
  },
  setApplication: application => application,
};

export const ApplicationContext = createContext(
  initialApplicationContextValues
);

export const ApplicationProvider: React.FC<Props> = ({ children, value }) => {
  const [application, setApplication] = useState(value);

  return (
    <ApplicationContext.Provider
      value={{
        application,
        setApplication,
      }}>
      {children}
    </ApplicationContext.Provider>
  );
};

import { parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz';

const timeZone = 'UTC+02:00';
export const datePickerFormat = 'dd/MM/yyyy';
export const dv = startDate => utcToZonedTime(new Date(startDate), timeZone);

export function formatUTCToDatePicker(date: string): string {
  if (!date) return '';
  return format(utcToZonedTime(new Date(date), timeZone), datePickerFormat);
}

export function formatDateRangeMed(startDate: string, endDate: string): string {
  const start = format(
    utcToZonedTime(new Date(startDate), timeZone),
    'dd MMM yyyy'
  );
  const end = format(
    utcToZonedTime(new Date(endDate), timeZone),
    'dd MMM yyyy'
  );
  return `${start} - ${end}`;
}

export function dateStringToISOString(dateStr: string) {
  const splitDate = dateStr.split('/');
  const year = Number(splitDate[2]);
  const month = Number(splitDate[1]) - 1;
  const day = Number(splitDate[0]);
  const date = new Date(year, month, day);

  const utcDate = zonedTimeToUtc(date, timeZone);
  return utcDate.toISOString();
}

// expect format dd/mm/yyyy
export function makeDateFromString(dateStr: string): Date {
  const splitDate = dateStr.split('/');
  const year = Number(splitDate[2]);
  const month = Number(splitDate[1]) - 1;
  const day = Number(splitDate[0]);

  return new Date(year, month, day);
}

export function dateToDisplayDate(date?: Date | string): string {
  try {
    if(typeof date === 'string') {
      if (date !== ''){
        const workingDate = parseISO(date);
        return workingDate ? format(workingDate, 'dd/MM/yyyy') : '';    
      }
      else {
        return '';
      }
    } else {
      if (!date){
        return '';
      }
      return date ? format(date, 'dd/MM/yyyy') : '';  
    }
  } catch (error) {
    return '';
  }
}

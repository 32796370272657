import { LISubmitApplication } from '@src/models';
import getConfig from 'next/config';

export const lifeInsuredSubmitApplication = async (application: LISubmitApplication): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(application),
  };

  await fetch(`${publicRuntimeConfig.host}/api/lisubmitapplication`, requestOptions);
};

import { ProgressSection, FormData } from '@src/models';
import getConfig from 'next/config';

export const saveApplicationPartial = async (
  applicationId: string,
  progress: ProgressSection[],
  formData: FormData,
  shouldSendRAREmail: boolean,
  isFmiReplacement: boolean,
  isOtherReplacement: boolean,
): Promise<any> => {
  const { publicRuntimeConfig } = getConfig();

  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      applicationId,
      formData,
      progress,
      shouldSendRAREmail,
      isFmiReplacement,
      isOtherReplacement,
    }),
  };

  await fetch(`${publicRuntimeConfig.host}/api/application`, requestOptions);
};

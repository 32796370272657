import { useContext } from 'react';
import { useRouter } from 'next/router';
import { useSection } from '@src/hooks';
import { UserContext } from '@src/contexts';
import { toSlug } from '@src/utils';
import { useProgress } from '.';

export function useNavigation() {
  const router = useRouter();
  const { updateProgress, userProgress, setApplicationSaving } = useProgress();
  const { user, setUser } = useContext(UserContext);
  const { nextSection, activeSection, nextSubSection } = useSection();

  /**
   * Hook used to navigate to a page
   * @param section The page name that you want to navigate to
   * @param showform Query param to show and hide the stepper on mobile devices
   */
  async function navigate(section: string, showform = false): Promise<boolean> {
    const showFormQuery = showform ? `?showform=${showform}` : '';

    await router
      .push(
        `/app/[id]/${toSlug(section)}${showFormQuery}`,
        `/app/${router.query.id}/${toSlug(section)}${showFormQuery}`
      )
      .then(() => {
        window.scrollTo(0, 0);
      });
    return true;
  }

  /**
   * Hook used to navigate to the next subsequent section
   */
  async function navigateToNextSection() {
    setApplicationSaving(true);
    await updateProgress()
      .then(() => setApplicationSaving(false))
      .catch(() => setApplicationSaving(false));

    if (nextSubSection) {
      navigate(nextSubSection.name);
    } else {
      const currentSectionIndex =
        userProgress.findIndex(x => x.key === activeSection.key) + 1;
      const subsequentSections = userProgress.slice(
        currentSectionIndex,
        userProgress.length
      );

      // find the next / current incomplete section
      const nextIncompleteSection = subsequentSections.find(x => !x.isComplete);

      // if there is a next incomplete section
      if (nextIncompleteSection) {
        // if the next incomplete section has subsections
        if (nextIncompleteSection.subsections.length > 0) {
          // find the first incomplete subsection
          const nextIncompleteSubSection = nextIncompleteSection.subsections.find(
            x => !x.isComplete
          );
          // if there is an incomplete subsection the navigate to it
          if (nextIncompleteSubSection) {
            navigate(nextIncompleteSubSection.name);
          }
          // else navigate to the first subsection
          else {
            navigate(nextIncompleteSection.subsections[0].name);
          }
        }

        // else navigate to the next incomplete section
        else {
          navigate(nextIncompleteSection.name);
        }
      }
      // if all sections are completed
      else {
        if (user.isLifeInsured) {
          const incompleteSections = userProgress.find(x => !x.isComplete);
          // if there are any incomplete sections navigate to validation-overview
          if (incompleteSections) {
            navigate('validation-overview');
          }
          // if there are no incomplete sections then show the modal
          else {
            setUser({ ...user, reviewModalOpen: true });
          }
        }
        // if adviser then just navigate to done
        else {
          navigate('done');
        }
      }
    }
  }

  function onAuraNextClick() {
    navigateToNextSection();
  }

  /**
   * TODO: Navigate to the previous page
   * @param e React mouse event
   */
  function navigateBack(e?: React.MouseEvent<HTMLButtonElement>): void {
    if (e) {
      e.preventDefault();
    }
    alert('TODO: Handle back click');
  }

  /**
   * Navigate to the done screen
   */
  async function navigateToDone() {
    await updateProgress();
    navigate('done', true);
  }

  return {
    navigate,
    navigateToNextSection,
    onAuraNextClick,
    navigateBack,
    navigateToDone,
  };
}

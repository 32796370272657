import { ProgressSection, FormData } from '../models';

export const formDataInitialValues: FormData = {
  lifeInsuredsDetails: {
    title: '',
    firstName: '',
    surname: '',
    maidenName: '',
    idPassport: '',
    idNumber: '',
    maritalStatus: '',
    passportNumber: '',
    cellphoneNumber: '',
    workNumber: '',
    emailAddress: '',
    communicationPreference: '',
    postalSameAsPhysical: '',
    physicalAddress: '',
    postalAddress: '',
    idWorkOutsideRsa: '',
    idPermanentResident: '',
    nationality: '',
    passportPermanentResident: '',
    uploadedDocuments: [],
  },
  policyholderDetails: {
    isPolicyholder: '',
    insuranceType: '',
    policyholders: [],
  },
  lifeLumpSumWholeOfLife: {
    beneficiaries: [],
  },
  lifeLumpSumTermLife: {
    beneficiaries: [],
  },
  ifaAgreement: {
    acceptedDate: null,
    accepted: false,
    declaration: '',
  },
  popiaConsent: {
    acceptedDate: null,
    accepted: false,
    declaration: '',
  },
  occupationHistory: {
    changedRecently: '',
    previousOccupations: [],
  },
  physicalDuties: {
    installationRequirement: false,
    installationRequirementHours: 0,
    manualWork: false,
    manualWorkHours: 0,
    restrainingRequirement: false,
    restrainingRequirementHours: 0,
    handHeldMachinery: false,
    handHeldMachineryHours: 0,
    heavyMachinery: false,
    heavyMachineryHours: 0,
    motorisedMachinery: false,
    motorisedMachineryHours: 0,
    liftingObjects: false,
    liftingObjectsHours: 0,
    physicalInspection: false,
    physicalInspectionHours: 0,
    workUnderground: false,
    workUndergroundHours: 0,
    workWithExplosives: false,
    workWithExplosivesHours: 0,
  },
  businessOverheadProtector: {
    totalBusinessIncome: 0,
    salaryDrawn: 0,
    totalMonthlyBusinessExpenses: 0,
    propertyRatesTaxes: 0,
    equipmentVehicleLeasingCost: 0,
    rentMortgagePremises: 0,
    legalAccountingAuditFees: 0,
    remunerationNonIncomeStaff: 0,
    businessCleaningLaundry: 0,
    generalInsurancePremiums: 0,
    businessMaintenanceExpenses: 0,
    subscriptionsToProfessionalAssociations: 0,
    advertising: 0,
    postage: 0,
    bankCharges: 0,
  },
  debitOrder: {
    accountHolder: '',
    accountHolderPhysicalAddress: '',
    dateOfBirth: null,
    accountHolderPostalAddress: '',
    fundSourceType: '',
    fundSourceOther: '',
    relation: '',
    detail: '',
    businessRegistrationNumber: '',
    fullname: '',
    contactNumber: '',
    email: '',
    nationality: '',
    idPassport: '',
    idNumber: '',
    passportNumber: '',
    bankName: '',
    bankBranch: '',
    bankCode: '',
    paymentStartDate: '',
    accountNumber: '',
    accountType: '',
    recurringPaymentDay: '',
  },
  medicalTests: {
    methodOfCollection: '',
    currentGP: {
      firstName: '',
      lastName: '',
      contactNumber: '',
      medicalFacility: '',
    },
    previousGP: {
      firstName: '',
      lastName: '',
      contactNumber: '',
      medicalFacility: '',
    },
  },
  covid19: {
    covidCloseContact: '',
    covidCloseContactDate: '',
    covidCloseContactDateUTC: '',
    covidTestedPositive: '',
    covidTestedPositiveDate: '',
    covidTestedPositiveDateUTC: '',
  },
  simultaneousApplications: {
    simultaneousApplications: '',
    simultaneousApplicationsDetails: [],
  },
  employmentStatus: {
    employmentType: '',
    employerName: '',
    employmentStartDate: '',
    employmentStartDateUTC: '',
    companySharePercentage: 0,
    businessIsBasedAtHome: '',
    businessAddress: '',
    industryCode: 0,
    industryName: '',
    industryOther: '',
    contracts: [],
  },
  lifeIncomeBenefits: {
    beneficiaryDetails: [],
  },
  astuteCheck: {
    clearStatus: '',
    notifications: null,
    fromCache: false,
    ageInCache: 0
  },
  insuranceHistory: {
    existing: '',
    policies: [],
  },  
  ficDeclaration: {
    isDpipOrFppo: false,
    dpipOrFppoIndividuals: [],
    individuals: []
  },
};

export const progressInitialValues: ProgressSection[] = [
  {
    key: '1',
    name: 'Life Insured’s Details',
    isComplete: false,
    subsections: [],
  },
  {
    key: '2',
    name: 'Policyholder Details',
    isComplete: false,
    subsections: [],
  },
  {
    key: '3',
    name: 'Financial Status',
    isComplete: false,
    subsections: [],
  },
  {
    key: '4',
    name: 'Occupation',
    isComplete: false,
    subsections: [
      {
        key: '4.1',
        name: 'Employment Status',
        isComplete: false,
      },
      {
        key: '4.2',
        name: 'Occupation History',
        isComplete: false,
      },
      {
        key: '4.3',
        name: 'Physical Duties',
        isComplete: false,
      },
      {
        key: '4.4',
        name: 'Business Overhead Protector',
        isComplete: false,
      },
    ],
  },
  {
    key: '5',
    name: 'Travel',
    isComplete: false,
    subsections: [],
  },
  {
    key: '6',
    name: 'Hobbies and Pursuits',
    isComplete: false,
    subsections: [],
  },
  {
    key: '7',
    name: 'Other Long Term Insurance',
    isComplete: false,
    subsections: [
      {
        key: '7.1',
        name: 'Simultaneous Applications',
        isComplete: false,
      },
      {
        key: '7.2',
        name: 'Existing Insurance',
        isComplete: false,
      },
      {
        key: '7.3',
        name: 'Insurance History',
        isComplete: false,
      },
    ],
  },
  {
    key: '8',
    name: 'Medical',
    isComplete: false,
    subsections: [
      {
        key: '8.1',
        name: 'Family History',
        isComplete: false,
      },
      {
        key: '8.2',
        name: 'Medical Details',
        isComplete: false,
      },
      {
        key: '8.3',
        name: 'Medical Assessment',
        isComplete: false,
      },
      {
        key: '8.4',
        name: 'COVID-19',
        isComplete: false,
      },
      {
        key: '8.5',
        name: 'Medical Tests & GP Details',
        isComplete: false,
      },
    ],
  },
  {
    key: '9',
    name: 'Beneficiaries',
    isComplete: false,
    subsections: [
      {
        key: '9.1',
        name: 'Income Benefits',
        isComplete: false,
      },
      {
        key: '9.2',
        name: 'Life Lump Sum (Term Life Cover)',
        isComplete: false,
      },
      {
        key: '9.3',
        name: 'Life Lump Sum (Whole of Life Cover)',
        isComplete: false,
      },
    ],
  },
  {
    key: '10',
    name: 'Debit Order',
    isComplete: false,
    subsections: [],
  },
  {
    key: '11',
    name: 'FIC Declaration',
    isComplete: false,
    subsections: [],
  },
  {
    key: '12',
    name: 'IFA Agreements',
    isComplete: false,
    subsections: [
      {
        key: '12.1',
        name: 'POPIA Consent',
        isComplete: false,
      },
      {
        key: '12.2',
        name: 'Independent Financial Adviser Agreement',
        isComplete: false,
      },
    ],
  },
];

import getConfig from 'next/config';
import { Signature } from '@src/models';

export const getSignature = async (applicationId, emailAddress): Promise<Signature | null> => {
  const { publicRuntimeConfig } = getConfig();
  return fetch(`${publicRuntimeConfig.host}/api/getSignature/${applicationId}/${emailAddress}`)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 404) {
        return null;
      }
      throw response.statusText;
    })
    .then(data => data)
    .catch(error => {
      console.log('Error in getSignature is', error);
      return null;
    });
};

export const getSignatureByRole = async (applicationId, signatoryRole): Promise<Signature | null> => {
  console.log('/src/utils/getSignatureByRole', applicationId, signatoryRole);
  const { publicRuntimeConfig } = getConfig();
  return fetch(`${publicRuntimeConfig.host}/api/getSignatureByRole/${applicationId}/${signatoryRole}`)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 404) {
        return null;
      }
      throw response.statusText;
    })
    .then(data => data)
    .catch(error => {
      console.log('Error in getSignatureByRole is', error);
      return null;
    });
};

function getBenefitType(benefit: string): string {
  const benefitCode = benefit.toLowerCase();
  if (benefitCode.includes('adult')) {
    return 'Adult';
  }
  if (benefitCode.includes('bus')) {
    return 'Business';
  }
  if (benefitCode.includes('child')) {
    return 'Child';
  }
  return '';
}

export { getBenefitType };

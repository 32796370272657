import React from 'react';
import { ProgressSection, Quote, FormData, User, Aura } from '@src/models';
import {
  ProgressProvider,
  QuoteProvider,
  FormDataProvider,
  SNLProvider,
  AuraProvider,
} from '.';
import { UserProvider } from './UserContext';
import { ApplicationProvider } from './ApplicationContext';

interface Contexts {
  children: any;
  application: any;
  progress: ProgressSection[];
  quote: Quote;
  formData: FormData;
  user: User;
  aura: Aura;
}
function ProviderComposer({ contexts, children }) {
  return contexts.reduceRight(
    (kids: any, parent: any) =>
      React.cloneElement(parent, {
        children: kids,
      }),
    children
  );
}

function ContextProvider({
  children,
  application,
  progress,
  quote,
  formData,
  user,
  aura,
}: Contexts) {
  return (
    <ProviderComposer
      contexts={[
        <ApplicationProvider value={application} />,
        <ProgressProvider value={progress} />,
        <QuoteProvider value={quote} />,
        <FormDataProvider value={formData} />,
        <SNLProvider />,
        <UserProvider value={user} />,
        <AuraProvider value={aura} />,
      ]}>
      {children}
    </ProviderComposer>
  );
}

export { ContextProvider, ProviderComposer };

import getConfig from 'next/config';
import { AuraDoctor, GetDoctorsResponse } from '@src/models';

export const fetchAuraGetDoctors = async (applicationId: string): Promise<AuraDoctor[]> => {
  const { publicRuntimeConfig } = getConfig();
  const result = await fetch(`${publicRuntimeConfig.host}/api/aura/doctor/addDoctor/${applicationId}`)
    .then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }
      throw Error(response.statusText);
    })
    .then(docResult => {
      docResult.etag && localStorage.setItem('AURA_ETAG', docResult.etag);
      return docResult as GetDoctorsResponse;
    })
    .catch(error => {
      console.log(`fetchAuraGetDoctors(${applicationId}) API call threw exception: ${error}`);
      return null;
    });
  return result.doctors as AuraDoctor[];
};

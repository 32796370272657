import { useContext, useState } from 'react';
import { SNLContext } from '@src/contexts';

type Props<T> = {
  emptyValue: T;
  values: T[];
  onChange: (cards: T[]) => Promise<void>;
};
export interface InteractiveSnlHook<T> {
  addItem: () => void;
  editItem: (keyChange: number) => void;
  onSave: (val: T) => Promise<boolean>;
  onClose: () => void;
  deleteItem: (keyChange: number) => void;
  selected: T;
}
export function useInteractiveSnl<T>({
  emptyValue: emptyCard,
  values,
  onChange,
}: Props<T>): InteractiveSnlHook<T> {
  const [key, setKey] = useState('0');
  const [selected, setSeleted] = useState<T>(emptyCard);
  const items = [...values];
  const { setIsSnlOpen } = useContext(SNLContext);
  const addItem = () => {
    setKey(items.length.toString());
    setSeleted({ ...emptyCard });
    setIsSnlOpen(true);
  };
  const editItem = keyChange => {
    setKey(keyChange);
    setSeleted(items[keyChange]);
    setIsSnlOpen(true);
  };
  const onSave = async (val: T) => {
    items[key] = val;
    await onChange(items);
    setSeleted(emptyCard);
    return true;
  };
  const onClose = () => {
    setIsSnlOpen(false);
  };
  const deleteItem = (keyChange: number) => {
    items.splice(keyChange, 1);
    onChange(items);
  };
  return {
    addItem,
    editItem,
    onSave,
    onClose,
    deleteItem,
    selected,
  };
}

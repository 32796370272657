import { useState, useEffect } from 'react';

function getDeviceConfig(width: number): string {
  if (width < 960 && width > 719) {
    return 'tablet';
  }
  if (width < 720) {
    return 'mobile';
  }

  return 'desktop';
}

function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(null);

  useEffect(() => {
    const calcInnerWidth = () => setBreakpoint(getDeviceConfig(window.innerWidth));
    calcInnerWidth();
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return breakpoint;
}

export { useBreakpoint };

// expect string as dd/mm/yyyy
export const checkDateIsValid = (dateStr: string): boolean => {
  // first check that the input value matches the string format dd/mm/yyyy
  // hyphens (-) ok too
  const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  const isMatch = dateStr.match(dateformat);

  // assuming input is correct, check validity of date
  let isValid = true;
  if (isMatch) {
    const splitDate = dateStr.split('/');
    const year = Number(splitDate[2]);
    const month = Number(splitDate[1]) - 1;
    const day = Number(splitDate[0]);
    const date = new Date(year, month, day, 23, 59);

    // does date match the input
    const legit =
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day;

    if (!legit) isValid = false;
  }

  return isMatch && isValid;
};
